import React from 'react';
import Clock from '../../components/Clock';

const ClockPage: React.FC = () => {
    return (
        <div className="clock-page">
            <Clock />
        </div>
    );
};

export default ClockPage;
