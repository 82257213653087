import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './pages/Main/Main';
import ClockPage from './pages/Clock/Clock';
import QWERPage from './pages/QWER/QWER';
import CalendarPage from './pages/Calendar/Calendar';
import ColorPage from './pages/Color/Color'

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/Clock" element={<ClockPage />} />
                <Route path="/QWER" element={<QWERPage />} />
                <Route path="/Calendar" element={<CalendarPage />} />
                <Route path="/Color" element={<ColorPage />} />
            </Routes>
        </Router>
    );
};

export default App;
