import React, { useEffect, useState } from 'react';

const Clock: React.FC = () => {
    const [time, setTime] = useState<string>(new Date().toLocaleTimeString());

    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date().toLocaleTimeString());
        }, 1000);

        return () => {
            clearInterval(timerId);
        };
    }, []);

    const clockStyle: React.CSSProperties = {
        backgroundColor: '#FFC0CB',
        color: 'black',
        fontSize: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    };

    return (
        <div style={clockStyle}>
            {time}
        </div>
    );
};

export default Clock;
