import React from 'react';
import { Link } from 'react-router-dom';

const Main: React.FC = () => {
    const containerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: 'pink',
        color: 'black',
        margin: 0,
        padding: '20px',
        textAlign: 'center',
    };

    const headerStyle: React.CSSProperties = {
        marginBottom: '50px',
        fontWeight: 'normal',
        fontSize: '60px',
    };

    const servicesContainerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '90%',
        maxWidth: '800px',
        borderRadius: '30px',
        backgroundColor: 'white',
        border: '2px solid white',
        padding: '30px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    };

    const serviceItemStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '20px 0',
    };

    const serviceNameStyle: React.CSSProperties = {
        fontSize: '24px',
        fontWeight: 'bold',
        textAlign: 'left',
    };

    const serviceDescriptionStyle: React.CSSProperties = {
        textAlign: 'center',
        flex: 1,
        fontSize: '18px',
    };

    const serviceButtonStyle: React.CSSProperties = {
        backgroundColor: '#FF00FF',
        color: 'white',
        border: 'none',
        padding: '12px 24px',
        borderRadius: '5px',
        textDecoration: 'none',
        fontSize: '16px',
    };

    const dividerStyle: React.CSSProperties = {
        width: '100%',
        height: '2px',
        backgroundColor: 'black',
        margin: '15px 0',
    };

    return (
        <div style={containerStyle}>
            <h1 style={headerStyle}>Welcome</h1>
            <div style={servicesContainerStyle}>
                <div style={serviceItemStyle}>
                    <div style={serviceNameStyle}>Calendar</div>
                    <div style={serviceDescriptionStyle}>사실상 QWER 스케줄 캘린더...?</div>
                    <Link to="/Calendar" style={serviceButtonStyle}>바로가기</Link>
                </div>
                <div style={dividerStyle}></div>
                <div style={serviceItemStyle}>
                    <div style={serviceNameStyle}>Clock</div>
                    <div style={serviceDescriptionStyle}>닉값</div>
                    <Link to="/Clock" style={serviceButtonStyle}>바로가기</Link>
                </div>
                <div style={dividerStyle}></div>
                <div style={serviceItemStyle}>
                    <div style={serviceNameStyle}>QWER</div>
                    <div style={serviceDescriptionStyle}>큐떱이들 너무 이뻐우ㅜㅜ</div>
                    <Link to="/QWER" style={serviceButtonStyle}>바로가기</Link>
                </div>
                <div style={dividerStyle}></div>
                <div style={serviceItemStyle}>
                    <div style={serviceNameStyle}>Color</div>
                    <div style={serviceDescriptionStyle}>색칠공부</div>
                    <Link to="/Color" style={serviceButtonStyle}>바로가기</Link>
            </div>
            </div>
        </div>
    );
};

export default Main;
