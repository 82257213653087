import React, { useEffect } from 'react';

const importAllImages = (requireContext: __WebpackModuleApi.RequireContext) => {
    return requireContext.keys().map(requireContext) as string[];
};

const images = importAllImages(require.context('../asset/images', false, /\.(png|jpe?g|svg)$/));

const QWER: React.FC = () => {
    useEffect(() => {
        if (window.location.pathname !== '/QWER') return;

        const setOgTags = () => {
            const head = document.head;

            // Set og:image
            let ogImageMeta = document.querySelector('meta[property="og:image"]');
            if (!ogImageMeta) {
                ogImageMeta = document.createElement('meta');
                ogImageMeta.setAttribute('property', 'og:image');
                head.appendChild(ogImageMeta);
            }
            if (images.length > 0) {
                const randomImage = images[Math.floor(Math.random() * images.length)] as string;
                ogImageMeta.setAttribute('content', randomImage);
            }

            // Set og:title
            let ogTitleMeta = document.querySelector('meta[property="og:title"]');
            if (!ogTitleMeta) {
                ogTitleMeta = document.createElement('meta');
                ogTitleMeta.setAttribute('property', 'og:title');
                head.appendChild(ogTitleMeta);
            }
            ogTitleMeta.setAttribute('content', 'QWER');

            // Set document title
            document.title = 'QWER';
        };

        setOgTags();
    }, []);

    const style: React.CSSProperties = {
        backgroundColor: 'pink',
        width: '100vw',
        height: '100vh',
        margin: 0,
    };

    return <div style={style} />;
};

export default QWER;
