import React from 'react';
import Calendar from '../../components/Calendar';

const CalendarPage: React.FC = () => {
    return (
        <div className="Calendar-page">
            <Calendar />
        </div>
    );
};
export default CalendarPage;
