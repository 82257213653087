import React from 'react';
import Color from '../../components/Color';

const ColorPage: React.FC = () => {
    return (
        <div style={{ backgroundColor: '#FFC0CB', minHeight: '100vh', padding: '2rem' }}>
            <Color />
        </div>
    );
};

export default ColorPage;
