import React from 'react';
import QWER from '../../components/QWER';

const QWERPage: React.FC = () => {
    return (
        <div className='qwer-page'>
            <QWER />
        </div>
    );
};

export default QWERPage;
