import React, { useState } from "react";

interface Event {
    date: string;
    description: string;
}

const Calendar: React.FC = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [events, setEvents] = useState<Event[]>([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const ADMIN_PASSWORD = "1234";

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
    );

    const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
    );

    const handlePreviousMonth = () => {
        setCurrentDate(
            new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
        );
    };

    const handleNextMonth = () => {
        setCurrentDate(
            new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
        );
    };

    const handleAddEvent = (date: string, description: string) => {
        if (description.trim()) {
            setEvents([...events, { date, description }]);
        }
    };

    const renderDays = () => {
        const days = [];
        const startDay = firstDayOfMonth.getDay();
        const endDay = lastDayOfMonth.getDate();

        for (let i = 0; i < startDay; i++) {
            days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
        }

        for (let i = 1; i <= endDay; i++) {
            const date = `${currentDate.getFullYear()}-${
                currentDate.getMonth() + 1
            }-${i}`;
            const dayEvents = events.filter((event) => event.date === date);
            days.push(
                <div key={i} className="calendar-day">
                    <div className="date-number">{i}</div>
                    {dayEvents.map((event, index) => (
                        <div key={index} className="event">
                            {event.description}
                        </div>
                    ))}
                    {isAdmin && (
                        <button
                            onClick={() =>
                                handleAddEvent(
                                    date,
                                    prompt("Enter event description:") || ""
                                )
                            }
                        >
                            +
                        </button>
                    )}
                </div>
            );
        }

        return days;
    };

    const toggleAdminMode = () => {
        if (isAdmin) {
            setIsAdmin(false);
            alert("관리자 모드가 비활성화되었습니다.");
        } else {
            const password = prompt("관리자 비밀번호를 입력하세요:");
            if (password === ADMIN_PASSWORD) {
                setIsAdmin(true);
                alert("관리자 모드가 활성화되었습니다.");
            } else {
                alert("잘못된 비밀번호입니다.");
            }
        }
    };

    return (
        <div className="calendar-container">
            <style>
                {`
          .calendar-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 100vh;
            background-color: pink;
            position: relative;
          }

          .calendar {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
            border: 2px solid #ccc;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            background-color: #ffffff;
          }

          .calendar-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            background-color: #f8f8f8;
            border-bottom: 2px solid #ccc;
          }

          .calendar-header button {
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
            padding: 12px;
            border-radius: 4px;
            transition: background-color 0.3s ease;
          }

          .calendar-header button:hover {
            background-color: #e0e0e0;
          }

          .calendar-header h2 {
            margin: 0;
            font-size: 28px;
          }

          .calendar-grid {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 1px;
            background-color: #ddd;
          }

          .calendar-day {
            background-color: #fff;
            padding: 12px;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            position: relative;
          }

          .calendar-day.empty {
            background-color: #f0f0f0;
          }

          .calendar-day .date-number {
            font-weight: bold;
            margin-bottom: 8px;
          }

          .calendar-day .event {
            background-color: #d1e7ff;
            color: #333;
            padding: 6px;
            border-radius: 4px;
            font-size: 14px;
            margin-top: 6px;
            width: 100%;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .calendar-day button {
            position: absolute;
            bottom: 6px;
            right: 6px;
            background-color: #4caf50;
            color: white;
            border: none;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          .calendar-day button:hover {
            background-color: #45a049;
          }

          .calendar-day.header {
            font-weight: bold;
            background-color: #f0f0f0;
            text-align: center;
          }

          .admin-button {
            position: fixed;
            bottom: 10px;
            right: 10px;
            background-color: #f5f5f5;
            color: #ccc;
            border: none;
            border-radius: 8px;
            padding: 10px;
            font-size: 12px;
            cursor: pointer;
            opacity: 0.2;
            transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease;
          }

          .admin-button:hover {
            opacity: 0.5;
            background-color: #ddd;
            color: #666;
          }
        `}
            </style>
            <div className="calendar">
                <div className="calendar-header">
                    <button onClick={handlePreviousMonth}>&lt;</button>
                    <h2>
                        {currentDate.getFullYear()}년{" "}
                        {currentDate.toLocaleString("default", { month: "long" })}
                    </h2>
                    <button onClick={handleNextMonth}>&gt;</button>
                </div>
                <div className="calendar-grid">
                    {daysOfWeek.map((day, index) => (
                        <div key={index} className="calendar-day header">
                            {day}
                        </div>
                    ))}
                    {renderDays()}
                </div>
            </div>
            <button className="admin-button" onClick={toggleAdminMode}>
                관리자
            </button>
        </div>
    );
};

export default Calendar;
