import React, { useState } from 'react';

type RGB = {
    red: number;
    green: number;
    blue: number;
};

const rgbToHex = (r: number, g: number, b: number): string => {
    const toHex = (n: number) => n.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
};

const hexToRgb = (hex: string): RGB => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { red: r, green: g, blue: b };
};

const Color: React.FC = () => {
    const [color, setColor] = useState<RGB>({ red: 0, green: 0, blue: 0 });
    const [showCustomInput, setShowCustomInput] = useState(false);
    const [customColor, setCustomColor] = useState('');
    const [inputType, setInputType] = useState<'HEX' | 'RGB'>('HEX');

    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setColor(prevColor => ({
            ...prevColor,
            [name]: Number(value)
        }));
    };

    const handleCustomColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomColor(e.target.value);
    };

    const applyCustomColor = () => {
        if (inputType === 'HEX') {
            if (customColor.startsWith('#')) {
                try {
                    const rgb = hexToRgb(customColor);
                    setColor(rgb);
                } catch (error) {
                    alert('유효하지 않은 HEX 색상 코드입니다.');
                }
            } else {
                alert('HEX 색상 코드는 #로 시작해야 합니다.');
            }
        } else if (inputType === 'RGB') {
            const [r, g, b] = customColor.split(',').map(num => parseInt(num.trim(), 10));
            if (!isNaN(r) && !isNaN(g) && !isNaN(b) && r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255) {
                setColor({ red: r, green: g, blue: b });
            } else {
                alert('유효하지 않은 RGB 색상 값입니다.');
            }
        }
    };

    const handleInputTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newType = e.target.value as 'HEX' | 'RGB';
        setInputType(newType);
        setCustomColor('');
    };

    const hexColor = rgbToHex(color.red, color.green, color.blue);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            overflow: 'hidden'
        }}>
            <div style={{
                textAlign: 'center',
                maxWidth: '600px',
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '2rem' }}>
                    <div style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                        <label>
                            R:
                            <input
                                type="range"
                                name="red"
                                min="0"
                                max="255"
                                value={color.red}
                                onChange={handleColorChange}
                                style={{
                                    width: '150px',
                                    height: '10px',
                                    borderRadius: '5px',
                                    background: `linear-gradient(90deg, rgb(0, 0, 0) ${color.red / 255 * 100}%, rgb(255, 0, 0) ${color.red / 255 * 100}%)`,
                                    outline: 'none'
                                }}
                            />
                        </label>
                        <div style={{ fontSize: '1rem', marginTop: '0.5rem' }}>{color.red}</div>
                    </div>
                    <div style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                        <label>
                            G:
                            <input
                                type="range"
                                name="green"
                                min="0"
                                max="255"
                                value={color.green}
                                onChange={handleColorChange}
                                style={{
                                    width: '150px',
                                    height: '10px',
                                    borderRadius: '5px',
                                    background: `linear-gradient(90deg, rgb(0, 0, 0) ${color.green / 255 * 100}%, rgb(0, 255, 0) ${color.green / 255 * 100}%)`,
                                    outline: 'none'
                                }}
                            />
                        </label>
                        <div style={{ fontSize: '1rem', marginTop: '0.5rem' }}>{color.green}</div>
                    </div>
                    <div style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                        <label>
                            B:
                            <input
                                type="range"
                                name="blue"
                                min="0"
                                max="255"
                                value={color.blue}
                                onChange={handleColorChange}
                                style={{
                                    width: '150px',
                                    height: '10px',
                                    borderRadius: '5px',
                                    background: `linear-gradient(90deg, rgb(0, 0, 0) ${color.blue / 255 * 100}%, rgb(0, 0, 255) ${color.blue / 255 * 100}%)`,
                                    outline: 'none'
                                }}
                            />
                        </label>
                        <div style={{ fontSize: '1rem', marginTop: '0.5rem' }}>{color.blue}</div>
                    </div>
                </div>
                <div
                    style={{
                        width: '200px',
                        height: '200px',
                        borderRadius: '50%',
                        backgroundColor: `rgb(${color.red}, ${color.green}, ${color.blue})`,
                        border: '2px solid #fff',
                        margin: '1rem auto'
                    }}
                />
                <p style={{ fontSize: '1.2rem' }}>RGB : {color.red}, {color.green}, {color.blue}</p>
                <p style={{ fontSize: '1.2rem' }}>HEX : {hexColor}</p>
                <button
                    onClick={() => setShowCustomInput(!showCustomInput)}
                    style={{
                        padding: '10px 20px',
                        fontSize: '1rem',
                        cursor: 'pointer',
                        marginTop: '1rem',
                        backgroundColor: '#FF00FF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px'
                    }}
                >
                    {showCustomInput ? '접기' : '펼치기'}
                </button>
                {showCustomInput && (
                    <div style={{ marginTop: '1rem' }}>
                        <div>
                            <label>
                                구분 :
                                <select
                                    value={inputType}
                                    onChange={handleInputTypeChange}
                                    style={{ marginLeft: '10px', padding: '5px' }}
                                >
                                    <option value="HEX">HEX</option>
                                    <option value="RGB">RGB</option>
                                </select>
                            </label>
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                            <label>
                                {inputType} :
                                <input
                                    type="text"
                                    value={customColor}
                                    onChange={handleCustomColorChange}
                                    style={{
                                        marginLeft: '10px',
                                        padding: '5px',
                                        fontSize: '1rem'
                                    }}
                                />
                            </label>
                        </div>
                        <button
                            onClick={applyCustomColor}
                            style={{
                                padding: '10px 20px',
                                fontSize: '1rem',
                                cursor: 'pointer',
                                marginTop: '1rem',
                                backgroundColor: '#28a745',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '5px'
                            }}
                        >
                            적용하기
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Color;
